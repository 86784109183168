import { useCallback, useState } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useDropzone } from "react-dropzone";
import { useToast } from "native-base";
import { colors } from "../colors";
import { useUserIDToken } from "./useUserIDToken.web";
import { useCurrentTenantPrefix } from "./useCurrentTenantPrefix";
import { getBackendURLDedicated } from "../environment";

export const dragActiveStyle = (isDragAccept: boolean) =>
  isDragAccept
    ? {
        outline: `2px solid ${colors.primary}`,
        outlineOffset: "2px",
      }
    : undefined;

export const useUploadSignature = (
  doc: DocumentSnapshot<DocumentData>,
  updateDoc: (data: any) => Promise<void>
) => {
  const [isUploading, setUploading] = useState(false);
  const [tenantId] = useCurrentTenantPrefix();
  const getUserIDToken = useUserIDToken();
  const toast = useToast();

  const uploadFile = useCallback(
    async (file: File) => {
      const { name } = file;
      const extension = name.split(".").pop();
      const uploadId = self.crypto.randomUUID();
      const destination = `${tenantId ?? ""}/signatures/${doc.id}/${uploadId}.${extension}`;

      try {
        setUploading(true);
        const authToken = await getUserIDToken();

        const formData = new FormData();
        formData.append("file", file);
        formData.append("tenantId", tenantId ?? "");
        formData.append("destination", destination);
        formData.append("authToken", authToken ?? "");

        const response = await fetch(`${getBackendURLDedicated()}/upload`, {
          method: "POST",
          body: formData,
        });

        const json = await response.json();
        const { success, error, fileUrl } = json;

        if (success && fileUrl) {
          await updateDoc({ signatureImageUrl: fileUrl });
          toast.show({
            placement: "top",
            title: "Die Unterschrift wurde erfolgreich hochgeladen.",
          });
        } else {
          if (error) {
            toast.show({
              placement: "top",
              title: error,
            });
          } else {
            throw new Error(JSON.stringify(json));
          }
        }
      } catch (e) {
        console.error(e);
        toast.show({
          placement: "top",
          title:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
        });
      } finally {
        setUploading(false);
      }
    },
    [doc.id, tenantId, updateDoc, getUserIDToken, toast]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);

      for (const file of acceptedFiles) {
        try {
          await uploadFile(file);
        } catch (e) {
          console.error(e);
        }
      }

      setUploading(false);
    },
    [uploadFile, setUploading]
  );

  const dropzone = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxFiles: 1,
  });

  const { open: uploadSignature } = dropzone;

  return [uploadSignature, isUploading, dropzone] as const;
};
