import { useCallback, useContext } from "react";
import { CurrentUserContext } from "../App";

export const useCurrentTenantPrefix = () => {
  const currentUser = useContext(CurrentUserContext);

  const tenantId = currentUser?.tenantId;

  return [
    tenantId,
    useCallback(
      (path: string) => [tenantId ? `tenants/${tenantId}` : "", path].join("/"),
      [tenantId]
    ),
  ] as const;
};
