import React from "react";
import { Stack } from "native-base";
import Headline from "../typo/Headline";
import CreateUserButton from "../user/CreateUserButton";
import UsersList from "./components/UsersList";
import Spacer from "./components/Spacer";

export default function Users() {
  return (
    <Stack px={5} py={6}>
      <Headline title="Benutzer" />

      <CreateUserButton />

      <Spacer />

      <UsersList />
    </Stack>
  );
}
