import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { CurrentUserContext } from "../App";
import { UserRoles } from "../types.web";
import { useCurrentUserRoles } from "../hooks/useCurrentUserRoles";

type Props = {
  children: JSX.Element;
  roles?: UserRoles[];
};

export default function RequireAuth({ children, roles }: Props) {
  const currentUser = useContext(CurrentUserContext);
  const [userRoles] = useCurrentUserRoles();
  const location = useLocation();

  const hasAllowedRole =
    !roles || roles.some((role) => userRoles.includes(role));

  if (!currentUser || !hasAllowedRole) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return children;
}
