import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { isEmpty } from "lodash";

// we don't need to check the local file system as this only applies to mobile
export const usePhotoThumbnail = (
  photo: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>,
  showOriginal?: boolean
) => {
  const { url, thumbnailUrl } = photo?.data() || {};

  const isUploading = isEmpty(url);

  let imageURI: string | undefined = undefined;

  // fallback to local image in case the images have not been uploaded yet
  if (showOriginal) {
    imageURI = url;
  } else {
    // remotely we prefer thumbnails, if available
    imageURI = thumbnailUrl ?? url;
  }

  return [imageURI, isUploading] as const;
};
