import { Select, Input, HStack, FormControl } from "native-base";
import { ProjectStates } from "../../../../hooks/useProjectState";

export type ProjectFilterType = "all" | "current" | ProjectStates;

type Props = {
  projectFilter: ProjectFilterType;
  setProjectFilter: (projectFilter: ProjectFilterType) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
};

const options: { label: string; value: ProjectFilterType }[] = [
  {
    label: "Aktiv",
    value: "current",
  },
  {
    label: "Zu erfassen",
    value: "toCapture",
  },
  {
    label: "In Bearbeitung",
    value: "inProgress",
  },
  {
    label: "Abgeschlossen",
    value: "done",
  },
  {
    label: "Alle",
    value: "all",
  },
];

export default function ProjectListFilter({
  projectFilter,
  setProjectFilter,
  searchTerm,
  setSearchTerm,
}: Props) {
  return (
    <HStack space={4} w="150">
      <FormControl mb="1">
        <FormControl.Label>Filter</FormControl.Label>
        <Select
          selectedValue={projectFilter}
          placeholder="Status"
          onValueChange={setProjectFilter as any}
          bg="white"
          w="full"
        >
          {options.map(({ label, value }) => (
            <Select.Item key={value} label={label} value={value} />
          ))}
        </Select>
      </FormControl>

      <FormControl mb="1" w="400">
        <FormControl.Label>Suche</FormControl.Label>

        <Input
          placeholder="Projektnummer oder -name"
          value={searchTerm}
          onChangeText={setSearchTerm}
          w="full"
        />
      </FormControl>
    </HStack>
  );
}
