import { useEffect, useState } from "react";
import {
  DocumentData,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

export const useObjectWebAccessAccounts = (
  projectId: string,
  objectId: string
) => {
  const [tenantId, , doc, collection] = useFirestore();
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);

  useEffect(() => {
    setIsLoading(true);

    const objectRef = doc(
      ["projects", projectId, "objects", objectId].join("/")
    );

    const q = query(
      collection("webAccessAccounts"),
      where("objectRef", "==", objectRef)
    );

    const unsub = onSnapshot(q, (snapshot) => {
      setAccounts(snapshot.docs);

      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  }, [tenantId, setIsLoading, setAccounts, projectId, objectId]);

  return [accounts, isLoading] as const;
};
