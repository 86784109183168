import {
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { ProjectFilterType } from "../components/web/components/table/ProjectListFilter";
import { useFirestore } from "./useFirestore.web";

export const useProjectOverview = (projectFilter: ProjectFilterType) => {
  const [tenantId, , , collection] = useFirestore();
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);

  useEffect(() => {
    setIsLoading(true);

    const filterConditions: QueryConstraint[] = [];

    switch (projectFilter) {
      case "all":
        break;

      case "current":
        filterConditions.push(
          where("state", "in", ["toCapture", "inProgress"])
        );
        break;

      case "toCapture":
      case "inProgress":
      case "done":
        filterConditions.push(where("state", "==", projectFilter));
        break;
    }

    const q = query(
      collection("projects"),
      orderBy("projectNumber", "desc"),
      ...filterConditions
    );

    const unsub = onSnapshot(q, (snapshot) => {
      setProjects(snapshot.docs);
      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  }, [tenantId, setIsLoading, setProjects, projectFilter]);

  return [projects, isLoading] as const;
};
