import React from "react";
import { DocumentSnapshot } from "firebase/firestore";
import Headline from "../../typo/Headline";
import TextFormInput from "../../form/TextFormInput";
import LogoUpload from "./LogoUpload";

interface EditTenantFormProps {
  tenant: DocumentSnapshot;
  updateTenant: (data: any) => Promise<void>;
}

export default function EditTenantForm({
  tenant,
  updateTenant,
}: EditTenantFormProps) {
  const tenantData = tenant.data();

  return (
    <div>
      <Headline title="Mandant bearbeiten" />

      <TextFormInput
        label="Name"
        fieldPath="name"
        doc={tenant as any}
        updateDoc={updateTenant as any}
      />

      <TextFormInput
        label="Hauptfarbe"
        fieldPath="primaryColor"
        doc={tenant as any}
        updateDoc={updateTenant as any}
      />

      <TextFormInput
        label="Website"
        fieldPath="website"
        doc={tenant as any}
        updateDoc={updateTenant as any}
      />

      <LogoUpload doc={tenant as any} updateDoc={updateTenant as any} />
    </div>
  );
}
