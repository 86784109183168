import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "native-base";
import { useTenants } from "../../../hooks/useTenants";
import ActiveState from "../../shared/ActiveState";

export default function TenantsList() {
  const [tenants, userCounts, isLoading] = useTenants();

  const navigate = useNavigate();

  const editTenant = useCallback(
    (tenantId: string) => {
      navigate(`/admin/tenants/edit/${tenantId}`);
    },
    [navigate]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Anzahl Benutzer (Aktiv/Total)</th>
          <th>Status</th>
          <th>Aktionen</th>
        </tr>
      </thead>
      <tbody>
        {tenants.map((tenant) => {
          const tenantData = tenant.data();
          const { id } = tenant;
          const { name, isActive } = tenantData;

          return (
            <tr key={id}>
              <td>{name}</td>
              <td>
                {userCounts[id]?.active ?? 0}/{userCounts[id]?.total ?? 0}
              </td>
              <td>
                <ActiveState isActive={isActive} />
              </td>
              <td>
                <Button variant="outline" onPress={() => editTenant(id)}>
                  Bearbeiten
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
