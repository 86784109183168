import { useCallback } from "react";
import { addDoc, Timestamp } from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

export const useCreateObject = (projectId: string) => {
  const [tenantId, , , collection] = useFirestore();

  return useCallback(
    async () =>
      addDoc(collection(["projects", projectId, "objects"].join("/")), {
        createdAt: Timestamp.now(),
      }),
    [tenantId]
  );
};
