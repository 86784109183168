import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, useToast } from "native-base";
import { Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import Headline from "../typo/Headline";
import { formikErrorMessages } from "../../helper/formik-helper";
import FormikTextFormInput from "../form/FormikTextFormInput";
import { addDoc, collection, doc, writeBatch } from "firebase/firestore";
import { firestore } from "../../App.web";
import areaSuggestions from "../../seed-data/areaSuggestions.json";
import crackSuggestions from "../../seed-data/crackSuggestions.json";
import damageSuggestions from "../../seed-data/damageSuggestions.json";
import positionSuggestions from "../../seed-data/positionSuggestions.json";
import widthSuggestions from "../../seed-data/widthSuggestions.json";

const initialValues = {
  name: "",
  primaryColor: "#000000",
  website: "",
};

const suggestionsToSeed = {
  areaSuggestions: areaSuggestions,
  crackSuggestions: crackSuggestions,
  damageSuggestions: damageSuggestions,
  positionSuggestions: positionSuggestions,
  widthSuggestions: widthSuggestions,
};

type FormValues = typeof initialValues;

const validationSchema = object().shape({
  name: string().required(formikErrorMessages.required),
  primaryColor: string().required(formikErrorMessages.required),
  website: string().required(formikErrorMessages.required),
});

export default function CreateTenant() {
  const toast = useToast();
  const navigate = useNavigate();

  const createTenant = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { setSubmitting } = formikHelpers;

      const tenantRef = await addDoc(collection(firestore, "tenants"), {
        ...values,
        createdAt: new Date().toISOString(),
        isActive: true,
      });

      const batch = writeBatch(firestore);

      for (const [suggestion, data] of Object.entries(suggestionsToSeed)) {
        for (const d of data) {
          const docRef = doc(
            collection(firestore, [tenantRef.path, suggestion].join("/"))
          );

          batch.set(docRef, d);
        }
      }

      await batch.commit();

      toast.show({
        placement: "top",
        title: "Der Mandant wurde erfolgreich erstellt.",
      });

      navigate("/admin/tenants");
      setSubmitting(false);
    },
    [navigate, toast]
  );

  return (
    <Stack px={5} py={6}>
      <Headline title="Mandant erstellen" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createTenant}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Stack space={4}>
            <FormikTextFormInput fieldPath="name" label="Name" />

            <FormikTextFormInput fieldPath="primaryColor" label="Hauptfarbe" />

            <FormikTextFormInput fieldPath="website" label="Website" />

            <Button
              isLoading={isSubmitting}
              onPress={() => handleSubmit()}
              alignSelf="flex-start"
            >
              Mandant erstellen
            </Button>
          </Stack>
        )}
      </Formik>
    </Stack>
  );
}
