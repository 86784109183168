import React from "react";
import { Stack } from "native-base";
import Headline from "../typo/Headline";
import CreateBranchButton from "../branch/CreateBranchButton";
import BranchesList from "./components/BranchesList";
import Spacer from "./components/Spacer";

export default function Branches() {
  return (
    <Stack px={5} py={6}>
      <Headline title="Niederlassungen" />

      <CreateBranchButton />

      <Spacer />

      <BranchesList />
    </Stack>
  );
}
