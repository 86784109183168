import React from "react";
import VermflexLogo from "../../../assets/vermflex-logo.png";
import { Tenant } from "../WebAccess";

type Props = {
  tenant?: Tenant;
};

export default function TenantLogo({ tenant }: Props) {
  return (
    <img
      src={tenant?.logoImageUrl || VermflexLogo}
      width={300}
      alt={tenant?.name || "Logo"}
    />
  );
}
