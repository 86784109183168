import React from "react";
import { DocumentSnapshot } from "firebase/firestore";
import { Stack } from "native-base";
import { SuggestionType } from "../../../hooks/useSuggestions";
import TextFormInput from "../../form/TextFormInput";
import TextAreaFormInput from "../../form/TextAreaFormInput";

interface EditSuggestionFormProps {
  suggestion: DocumentSnapshot;
  type: SuggestionType;
  updateSuggestion: (data: any) => Promise<void>;
}

export default function EditSuggestionForm({
  suggestion,
  type,
  updateSuggestion,
}: EditSuggestionFormProps) {
  return (
    <Stack space={4}>
      <TextFormInput
        label="Titel"
        fieldPath="title"
        doc={suggestion as any}
        updateDoc={updateSuggestion as any}
      />

      <TextAreaFormInput
        label="Beschreibung"
        fieldPath="description"
        doc={suggestion as any}
        updateDoc={updateSuggestion as any}
      />

      {type === "area" && (
        <>
          <TextFormInput
            label="Level (kommagetrennt)"
            fieldPath="levels"
            doc={suggestion as any}
            updateDoc={async (value) => {
              const levels = value.levels
                .split(",")
                .map((l: string) => parseInt(l.trim()))
                .filter((l: number) => !isNaN(l));
              await updateSuggestion({ levels });
            }}
          />

          <TextFormInput
            label="Verfügbar in Bereichen (kommagetrennt)"
            fieldPath="availableInAreas"
            doc={suggestion as any}
            updateDoc={async (value) => {
              const areas = value.availableInAreas
                .split(",")
                .map((a: string) => a.trim())
                .filter(Boolean);
              await updateSuggestion({ availableInAreas: areas });
            }}
          />
        </>
      )}
    </Stack>
  );
}
