import React, { useState } from "react";
import { Box, HStack, Select, Spinner, Stack } from "native-base";
import Headline from "../typo/Headline";
import { useStatistics } from "../../hooks/useStatistics";
import { useTenants } from "../../hooks/useTenants";

const MONTHS = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

const METRICS = [
  { key: "activeUsers", label: "Aktive Benutzer" },
  { key: "totalUsers", label: "Benutzer Total" },
  { key: "totalBranches", label: "Niederlassungen" },
  { key: "totalProjects", label: "Projekte" },
  { key: "totalObjects", label: "Objekte" },
] as const;

export default function Statistics() {
  const [tenants] = useTenants();
  const [selectedYear, setSelectedYear] = useState(2025);
  const [selectedTenantId, setSelectedTenantId] = useState<string>("");
  const [monthlyStats, isLoading] = useStatistics(
    selectedYear,
    selectedTenantId
  );

  if (selectedTenantId && isLoading) {
    return <Spinner />;
  }

  return (
    <Stack px={5} py={6}>
      <Headline title="Statistiken" />

      <HStack space={4} mb={4}>
        <Box w={200}>
          <Select
            selectedValue={selectedTenantId}
            onValueChange={setSelectedTenantId}
            placeholder="Mandant auswählen..."
          >
            {tenants.map((tenant) => {
              const { id } = tenant;
              const { name, isActive } = tenant.data() ?? {};

              return (
                <Select.Item
                  key={id}
                  label={`${name}${!isActive ? " (Inaktiv)" : ""}`}
                  value={id}
                />
              );
            })}
          </Select>
        </Box>

        <Box w={200}>
          <Select
            selectedValue={selectedYear.toString()}
            onValueChange={(value) => setSelectedYear(parseInt(value))}
          >
            <Select.Item label="2025" value="2025" />
          </Select>
        </Box>
      </HStack>

      {selectedTenantId ? (
        <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>{METRICS.length + 1 === 0 ? "" : "Monat"}</th>
              {METRICS.map(({ label }) => (
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }} key={label}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {MONTHS.map((month, monthIndex) => (
              <tr key={month}>
                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{month}</td>
                {METRICS.map(({ key }) => (
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }} key={key}>
                    {monthlyStats[monthIndex as number]
                      ? monthlyStats[monthIndex as number]?.[key as keyof typeof monthlyStats[number]]
                      : "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Box>Bitte wählen Sie einen Mandanten aus.</Box>
      )}
    </Stack>
  );
}
