import React from "react";
import { HStack, Spinner, Stack } from "native-base";
import { useNavigate, useParams } from "react-router-dom";
import { SuggestionType } from "../../../hooks/useSuggestions";
import { useSuggestion } from "../../../hooks/useSuggestion";
import Headline from "../../typo/Headline";
import EditSuggestionForm from "./EditSuggestionForm";
import DeleteButton from "../../shared/DeleteButton.web";

export default function EditSuggestion() {
  const { suggestionId, type } = useParams();
  const navigate = useNavigate();

  const { suggestion, updateSuggestion, isLoading } = useSuggestion(
    suggestionId ?? "",
    type as SuggestionType
  );

  const afterDelete = () => {
    navigate(`/admin/suggestions/${type}`);
  };

  if (isLoading || !suggestion) {
    return <Spinner />;
  }

  return (
    <Stack px={5} py={6}>
      <HStack alignItems="center" justifyContent="space-between" mb={6}>
        <Headline
          title={suggestionId ? "Vorschlag bearbeiten" : "Vorschlag erstellen"}
        />
      </HStack>

      <EditSuggestionForm
        type={type as SuggestionType}
        suggestion={suggestion as any}
        updateSuggestion={updateSuggestion as any}
      />

      {suggestionId && suggestion && (
        <DeleteButton
          title="Vorschlag löschen"
          description="Möchten Sie diesen Vorschlag wirklich löschen?"
          object={suggestion as any}
          afterDelete={afterDelete}
        />
      )}
    </Stack>
  );
}
