import React from "react";
import { Spinner, Stack } from "native-base";
import { useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import ChangePassword from "./user/ChangePassword";
import ChangeStatus from "./user/ChangeStatus";
import EditUserForm from "./user/EditUserForm";
import ChangeRole from "./user/ChangeRole";

export default function EditUser() {
  const { userId } = useParams();

  const { user, updateUser, isLoading } = useUser(userId ?? "");

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Stack space={8} px={5} py={6}>
      <EditUserForm user={user as any} updateUser={updateUser as any} />

      <div>
        <ChangePassword user={user as any} />
      </div>

      <div>
        <ChangeRole user={user as any} />
      </div>

      <div>
        <ChangeStatus user={user as any} />
      </div>
    </Stack>
  );
}
