import { addDoc, Timestamp } from "firebase/firestore";
import { useCallback } from "react";
import { useFirestore } from "./useFirestore.web";

export const useCreateAppointment = (projectId: string, objectId: string) => {
  const [tenantId, , doc, collection] = useFirestore();

  return useCallback(async () => {
    const projectRef = doc(["projects", projectId].join("/"));

    const objectRef = doc(
      ["projects", projectId, "objects", objectId].join("/")
    );

    addDoc(collection("appointments"), {
      state: "open",
      projectRef,
      objectRef,
      createdAt: Timestamp.now(),
    });
  }, [tenantId, projectId, objectId]);
};
