import { useFirebaseObject } from "./useFirebaseObject";

export const roleOptions = [
  { label: "Super-Administrator", value: "superadmin" },
  { label: "Administrator", value: "admin" },
  { label: "Benutzer", value: "user" },
];

export const useUser = (userId: string) => {
  const [user, updateUser, isLoading] = useFirebaseObject(
    ["users", userId].join("/")
  );

  return { user, updateUser, isLoading };
};
