import { useCallback } from "react";
import { updateDoc } from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

export const useUpdateFirebaseObject = (docPath: string) => {
  const [tenantId, , doc] = useFirestore();

  return useCallback(
    async (data: any) => {
      const docRef = doc(docPath);

      return await updateDoc(docRef, data);
    },
    [tenantId, docPath]
  );
};
