import { useCallback } from "react";
import { addDoc, Timestamp } from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

export const useCreateProject = () => {
  const [tenantId, , , collection] = useFirestore();

  return useCallback(
    async () =>
      addDoc(collection(["projects"].join("/")), {
        state: "toCapture",
        createdAt: Timestamp.now(),
      }),
    [tenantId]
  );
};
