import { DocumentSnapshot } from "firebase/firestore";
import { saveAs } from "file-saver";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";

const formatICSDate = (date: Date): string => {
  // First convert the date to UTC from Zurich time
  const utcDate = zonedTimeToUtc(date, "Europe/Zurich");
  // Then format it with Z to indicate it's UTC
  return formatInTimeZone(utcDate, "UTC", "yyyyMMdd'T'HHmmss'Z'");
};

const escapeICSText = (text: string | undefined): string => {
  if (!text) return "";
  return text
    .replace(/[\\,;]/g, (match) => "\\" + match)
    .replace(/\n/g, "\\n")
    .replace(/\r/g, "\\r");
};

export const generateICSContent = (
  appointment: DocumentSnapshot,
  object: DocumentSnapshot,
  project: DocumentSnapshot
): string | null => {
  const appointmentData = appointment.data();
  const objectData = object.data();
  const projectData = project.data();

  if (
    !appointmentData ||
    !objectData ||
    !projectData ||
    !appointmentData.appointmentDate
  ) {
    return null;
  }

  const startDate = appointmentData.appointmentDate.toDate();
  const duration = appointmentData.duration || 60; // default 60 minutes if not set
  const endDate = new Date(startDate.getTime() + duration * 60 * 1000);

  // Build detailed description with all required information
  const description = [
    // Project Information
    `Projektnummer: ${escapeICSText(projectData.projectNumber)}`,
    `Projektname: ${escapeICSText(projectData.title)}`,
    `Baustelle: ${escapeICSText(projectData.constructionSite)}`,
    "",
    // Object Information
    `Objekt: ${escapeICSText(objectData.title)}`,
    `Standort / Adresse: ${escapeICSText(objectData.address)}`,
    `Grundstücksnummer: ${escapeICSText(objectData.propertyNumber)}`,
    `Eigentümer: ${escapeICSText(objectData.owner)}`,
    "",
    // Appointment Notes if any
    appointmentData.notes
      ? `Notizen: ${escapeICSText(appointmentData.notes)}`
      : "",
  ]
    .filter(Boolean)
    .join("\r\n");

  const summary = `Rissprotokoll ${projectData.projectNumber ? `[${projectData.projectNumber}] ` : ""}${appointmentData.title ?? "Rissprotokoll Termin"}`;

  const icsContent = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "PRODID:-//Vermflex//Rissprotokoll//EN",
    "BEGIN:VEVENT",
    `UID:${appointment.id}@vermflex.com`,
    `DTSTAMP:${formatICSDate(new Date())}`,
    `DTSTART:${formatICSDate(startDate)}`,
    `DTEND:${formatICSDate(endDate)}`,
    `SUMMARY:${escapeICSText(summary)}`,
    `DESCRIPTION:${description.split("\r\n").join("\\n")}`,
    `LOCATION:${escapeICSText(objectData.address || "")}`,
    `URL:https://rissprotokoll.vermflex.ch/admin/projects/${project.id}/${object.id}`,
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\r\n");

  return icsContent;
};

export const exportToCalendar = (
  appointment: DocumentSnapshot,
  object: DocumentSnapshot,
  project: DocumentSnapshot
): void => {
  const icsContent = generateICSContent(appointment, object, project);

  if (!icsContent) {
    console.error("Could not generate calendar event: missing required data");
    return;
  }

  const blob = new Blob([icsContent], { type: "text/calendar;charset=utf-8" });
  const fileName = `Rissprotokoll-Termin-${appointment.id}.ics`;
  saveAs(blob, fileName);
};
