import React from "react";
import { DocumentSnapshot } from "firebase/firestore";
import Headline from "../../typo/Headline";
import TextFormInput from "../../form/TextFormInput";

interface EditBranchFormProps {
  branch: DocumentSnapshot;
  updateBranch: (data: any) => Promise<void>;
}

export default function EditBranchForm({
  branch,
  updateBranch,
}: EditBranchFormProps) {
  return (
    <div>
      <Headline title="Niederlassung bearbeiten" />

      <TextFormInput
        label="Name"
        fieldPath="name"
        doc={branch as any}
        updateDoc={updateBranch as any}
      />

      <TextFormInput
        label="Strasse"
        fieldPath="street"
        doc={branch as any}
        updateDoc={updateBranch as any}
      />

      <TextFormInput
        label="PLZ"
        fieldPath="zipCode"
        doc={branch as any}
        updateDoc={updateBranch as any}
      />

      <TextFormInput
        label="Ort"
        fieldPath="city"
        doc={branch as any}
        updateDoc={updateBranch as any}
      />
    </div>
  );
}
