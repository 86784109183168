import { useCallback } from "react";
import { updateDoc } from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

export const useUpdateAppointment = (appointmentId: string) => {
  const [tenantId, , doc] = useFirestore();

  return useCallback(
    (data: any) =>
      updateDoc(doc(["appointments", appointmentId].join("/")), data),
    [tenantId, appointmentId]
  );
};
