import { useEffect, useState } from "react";
import { useBranch } from "./useBranch";

export const useAuthorFields = (user: any) => {
  const [branchId, setBranchId] = useState("");
  const { branch } = useBranch(branchId);
  const [city, setCity] = useState("");

  useEffect(() => {
    const fetchBranchCity = async () => {
      const { branchRef } = user?.data() || {};
      if (!branchRef) return;

      setBranchId(branchRef.id);
    };

    fetchBranchCity();
  }, [user]);

  useEffect(() => {
    if (branch) {
      setCity(branch.data()?.city ?? "");
    }
  }, [branch, setCity]);

  if (!user) {
    return {
      name: "",
      eMail: "",
      phone: "",
      address: "",
      signatureImageUrl: "",
      city: "",
    };
  }

  const { protocolAuthor, phone, eMail, name, signatureImageUrl } =
    user.data() || {};

  return {
    name: name ?? "",
    eMail: eMail ?? "",
    phone: phone ?? "",
    address: protocolAuthor ?? "",
    signatureImageUrl: signatureImageUrl ?? "",
    city,
  };
};
