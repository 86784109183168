import { extendTheme } from "native-base";

const defaultColors = {
  primary: {
    "50": "#f6ff71",
    "100": "#f3ff49",
    "200": "#f0ff22",
    "300": "#e8f900",
    "400": "#c2cf00",
    "500": "#c2cf00",
    "600": "#c2cf00",
    "700": "#767e0c",
    "800": "#5f650e",
    "900": "#494e0e",
  },
  gray: {
    "50": "#ccd7dd",
    "100": "#b7c3c9",
    "200": "#a6afb3",
    "300": "#96999b",
    "400": "#828688",
    "500": "#828688",
    "600": "#828688",
    "700": "#715c52",
    "800": "#684f43",
    "900": "#5e4335",
  },
};

export const theme = extendTheme({
  colors: defaultColors,
});
