import { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { app } from "../App.web";

export interface TenantStatistics {
  tenantId: string;
  timestamp: string;
  totalUsers: number;
  activeUsers: number;
  totalProjects: number;
  totalObjects: number;
  totalBranches: number;
}

export interface DailyStatistics {
  date: string;
  tenants: {
    [key: string]: TenantStatistics;
  };
}

export function useStatistics(year: number, tenantId: string) {
  const [monthlyStats, setMonthlyStats] = useState<(TenantStatistics | null)[]>(
    Array(12).fill(null)
  );
  const [isLoading, setIsLoading] = useState(true);
  const db = getFirestore(app);

  useEffect(() => {
    const fetchMonthlyStats = async () => {
      try {
        const monthPromises = Array.from(
          { length: 12 },
          async (_, monthIndex) => {
            const startDate = Timestamp.fromDate(new Date(year, monthIndex, 1));
            const endDate = Timestamp.fromDate(
              new Date(year, monthIndex + 1, 0)
            );

            // Query for all statistics in this month
            const statisticsRef = collection(db, "statistics");
            const q = query(
              statisticsRef,
              where("date", ">=", startDate),
              where("date", "<=", endDate),
              orderBy("date", "desc"),
              limit(1)
            );

            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
              return null;
            }

            const doc = querySnapshot.docs[0].data() as DailyStatistics;
            return doc.tenants[tenantId] ?? null;
          }
        );

        const results = await Promise.all(monthPromises);
        setMonthlyStats(results);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching statistics:", error);
        setIsLoading(false);
      }
    };

    if (tenantId) {
      fetchMonthlyStats();
    }
  }, [year, tenantId, db]);

  return [monthlyStats, isLoading] as const;
}
