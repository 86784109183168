import { Platform } from "react-native";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { Button, HStack, Icon, VStack } from "native-base";
import { Feather } from "@expo/vector-icons";
import { useCreateProtocol } from "../../../hooks/useCreateProtocol";

type Props = {
  object: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  regenerateProtocol?: boolean;
  isPhotoUploadIncomplete?: boolean;
};

export default function CreateProtocolButton({
  object,
  regenerateProtocol,
  isPhotoUploadIncomplete,
}: Props) {
  const [createProtocol, isSubmitting] = useCreateProtocol(
    object.ref.parent.parent!.id,
    object.id,
  );

  if (!object) {
    return null;
  }

  const { protocolCreatedAt } = object.data() || {};

  const buttonOptions = [
    {
      title: "Protokoll",
      includeDetailPhotos: false,
    },
    {
      title: "Protokoll mit Detailfotos",
      includeDetailPhotos: true,
    },
  ];

  const buttons = buttonOptions.map(({ title, includeDetailPhotos }, idx) => (
    <Button
      key={`button-${idx}`}
      flex={1}
      leftIcon={
        regenerateProtocol ? <Icon as={Feather} name="rotate-cw" /> : undefined
      }
      variant={regenerateProtocol ? "outline" : "solid"}
      color="green.400"
      onPress={() => createProtocol(includeDetailPhotos)}
      isLoading={(protocolCreatedAt && !regenerateProtocol) || isSubmitting}
      isDisabled={isPhotoUploadIncomplete}
    >
      {regenerateProtocol ? `${title} neu generieren` : `${title} erstellen`}
    </Button>
  ));

  return Platform.OS === "web" ? (
    <HStack flex={1} space={4}>
      {buttons}
    </HStack>
  ) : (
    <VStack flex={1} space={4}>
      {buttons}
    </VStack>
  );
}
