import { useMemo, useCallback } from "react";
import {
  doc as firestoreDoc,
  collection as firestoreCollection,
} from "firebase/firestore";
import { firestore } from "../App.web";
import { useCurrentTenantPrefix } from "./useCurrentTenantPrefix";

export const useFirestore = () => {
  const [tenantId, withTenantPrefix] = useCurrentTenantPrefix();

  const store = useMemo(
    () => firestoreDoc(firestore, withTenantPrefix("").replace(/\/$/, "")),
    [tenantId]
  );

  const doc = useCallback(
    (docPath: string) =>
      firestoreDoc(firestore, withTenantPrefix(docPath).replace(/\/$/, "")),
    [tenantId]
  );

  const collection = useCallback(
    (collectionPath: string) =>
      firestoreCollection(
        firestore,
        withTenantPrefix(collectionPath).replace(/\/$/, "")
      ),
    [tenantId]
  );

  return [tenantId, store, doc, collection] as const;
};
