import React, { useCallback, useState } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Button, Stack, useToast, Text } from "native-base";
import { useUserIDToken } from "../../../hooks/useUserIDToken.web";
import Subsection from "../../typo/Subsection";
import { useCurrentTenantPrefix } from "../../../hooks/useCurrentTenantPrefix";
import RoleSelect from "./RoleSelect";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { formikErrorMessages } from "../../../helper/formik-helper";
import { roleOptions } from "../../../hooks/useUser";
import { getBackendURL } from "../../../environment";

type Props = {
  user: DocumentSnapshot<DocumentData>;
};

const validationSchema = object().shape({
  role: string()
    .oneOf(["superadmin", "admin", "user"])
    .required(formikErrorMessages.required),
});

export default function ChangeRole({ user }: Props) {
  const [tenantId] = useCurrentTenantPrefix();
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const getUserIDToken = useUserIDToken();

  const userData = user.data();

  if (!userData) {
    return null;
  }

  const { role, eMail } = userData;

  const roleName = roleOptions.find(({ value }) => value === role)?.label;

  const changeUserRole = useCallback(
    async (newRole: string) => {
      setLoading(true);

      const authToken = await getUserIDToken();

      try {
        const response = await fetch(`${getBackendURL()}/users/changeRole`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            authToken,
            tenantId,
            eMail,
            role: newRole,
          }),
        });

        const json = await response.json();

        const { success, error } = json;

        if (success) {
          toast.show({
            placement: "top",
            title: "Die Rolle wurde erfolgreich geändert.",
          });
        } else {
          if (error) {
            toast.show({
              placement: "top",
              title: error,
            });
          } else {
            throw new Error(JSON.stringify(json));
          }
        }
      } catch (e) {
        console.error(e);

        toast.show({
          placement: "top",
          title:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
        });
      }

      setLoading(false);
    },
    [tenantId, eMail, toast]
  );

  return (
    <Stack space={4}>
      <Subsection title="Rolle ändern" />

      <Formik
        initialValues={{ role }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await changeUserRole(values.role);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Stack space={4}>
              <Text>Aktuelle Rolle: {roleName}</Text>

              <RoleSelect label="Neue Rolle" />

              <Button
                isLoading={isLoading || isSubmitting}
                onPress={submitForm}
              >
                Rolle ändern
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}
