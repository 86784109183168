import { useEffect, useState } from "react";
import {
  DocumentData,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

export const useProjectWebAccessAccounts = (projectId: string) => {
  const [tenantId, , doc, collection] = useFirestore();
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);

  useEffect(() => {
    setIsLoading(true);

    const projectRef = doc(["projects", projectId].join("/"));

    const q = query(
      collection("webAccessAccounts"),
      where("projectRef", "==", projectRef)
    );

    const unsub = onSnapshot(q, (snapshot) => {
      setAccounts(snapshot.docs);

      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  }, [tenantId, setIsLoading, setAccounts, projectId]);

  return [accounts, isLoading] as const;
};
