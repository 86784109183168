import React from "react";
import { Box, HStack, Select, Stack } from "native-base";
import { useNavigate, useParams } from "react-router-dom";
import { SuggestionType } from "../../hooks/useSuggestions";
import Headline from "../typo/Headline";
import CreateSuggestionButton from "./suggestions/CreateSuggestionButton";
import SuggestionsList from "./suggestions/SuggestionsList";

const SUGGESTION_TYPES: { value: SuggestionType; label: string }[] = [
  { value: "crack", label: "Rissbeschriebe" },
  { value: "damage", label: "Schaden" },
  { value: "position", label: "Detailpositionen" },
  { value: "width", label: "Rissbreiten" },
  { value: "area", label: "Bereiche" },
];

export default function ManageSuggestions() {
  const navigate = useNavigate();
  const { type = "crack" } = useParams();

  return (
    <Stack px={5} py={6}>
      <Headline title="Textvorschläge verwalten" />

      <CreateSuggestionButton type={type as SuggestionType} />

      <HStack mb={4}>
        <Box w={200}>
          <Select
            selectedValue={type}
            onValueChange={(value) => navigate(`/admin/suggestions/${value}`)}
          >
            {SUGGESTION_TYPES.map(({ value, label }) => (
              <Select.Item key={value} label={label} value={value} />
            ))}
          </Select>
        </Box>
      </HStack>

      <SuggestionsList type={type as SuggestionType} />
    </Stack>
  );
}
