import { useCallback } from "react";
import { addDoc, Timestamp } from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

export const useCreateWebAccess = () => {
  const [tenantId, , doc, collection] = useFirestore();

  return useCallback(
    async (
      projectId: string,
      objectId: string | undefined,
      eMail: string,
      code: string
    ) => {
      const data: any = {
        eMail,
        code,
        createdAt: Timestamp.now(),
      };

      if (objectId) {
        data.objectRef = doc(
          ["projects", projectId, "objects", objectId].join("/")
        );
      } else {
        data.projectRef = doc(["projects", projectId].join("/"));
      }

      return addDoc(collection("webAccessAccounts"), data);
    },
    [tenantId]
  );
};
