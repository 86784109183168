import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, useToast } from "native-base";
import { Formik, FormikHelpers } from "formik";
import { object, string, ref } from "yup";
import Headline from "../typo/Headline";
import { formikErrorMessages } from "../../helper/formik-helper";
import FormikTextFormInput from "../form/FormikTextFormInput";
import { useUserIDToken } from "../../hooks/useUserIDToken.web";
import { useCurrentTenantPrefix } from "../../hooks/useCurrentTenantPrefix";
import { useCurrentUserRoles } from "../../hooks/useCurrentUserRoles";
import RoleSelect from "./user/RoleSelect";
import { getBackendURL } from "../../environment";

const initialValues = {
  eMail: "",
  name: "",
  role: "",
  password: "",
  passwordConfirmation: "",
};

type FormValues = typeof initialValues;

const validationSchema = object().shape({
  eMail: string()
    .email(formikErrorMessages.eMail)
    .required(formikErrorMessages.required),
  name: string().required(formikErrorMessages.required),
  role: string()
    .oneOf(["superadmin", "admin", "user"])
    .required(formikErrorMessages.required),
  password: string().required(formikErrorMessages.required),
  passwordConfirmation: string()
    .oneOf([ref("password")], formikErrorMessages.notMatching)
    .required(formikErrorMessages.required),
});

export default function CreateUser() {
  const [, hasRole] = useCurrentUserRoles();
  const [tenantId] = useCurrentTenantPrefix();
  const toast = useToast();
  const navigate = useNavigate();
  const getUserIDToken = useUserIDToken();

  const createUser = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { eMail, name, role, password } = values;
      const { setSubmitting } = formikHelpers;

      const authToken = await getUserIDToken();

      try {
        const response = await fetch(`${getBackendURL()}/users/create`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            authToken,
            tenantId,
            eMail,
            name,
            role,
            password,
          }),
        });

        const json = await response.json();

        const { success, error } = json;

        if (success) {
          toast.show({
            placement: "top",
            title: "Der Benutzer wurde erfolgreich erstellt.",
          });

          navigate("/admin/users");
        } else {
          if (error) {
            toast.show({
              placement: "top",
              title: error,
            });
          } else {
            throw new Error(JSON.stringify(json));
          }
        }
      } catch (e) {
        console.error(e);

        toast.show({
          placement: "top",
          title:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
        });
      }

      setSubmitting(false);
    },
    [tenantId, toast, navigate]
  );

  if (!(hasRole("admin") || hasRole("superadmin"))) {
    return null;
  }

  return (
    <Stack px={5} py={6}>
      <Headline title="Benutzer erstellen" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createUser}
      >
        {({ isSubmitting, submitForm }) => (
          <Stack>
            <FormikTextFormInput fieldPath="eMail" label="E-Mail" />

            <FormikTextFormInput fieldPath="name" label="Name" />

            <RoleSelect label="Rolle" />

            <FormikTextFormInput
              fieldPath="password"
              label="Passwort"
              type="password"
            />

            <FormikTextFormInput
              fieldPath="passwordConfirmation"
              label="Passwort bestätigen"
              type="password"
            />

            <Button
              onPress={submitForm}
              isLoading={isSubmitting}
              mt={8}
              alignSelf="flex-start"
            >
              Benutzer erstellen
            </Button>
          </Stack>
        )}
      </Formik>
    </Stack>
  );
}
