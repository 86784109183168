import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Spinner } from "native-base";
import React, { useCallback } from "react";
import { useBranches } from "../../../hooks/useBranches";
import { useFirestore } from "../../../hooks/useFirestore.web";
import SelectFormInput, { Option } from "../../form/SelectFormInput.web";
import TextAreaFormInput from "../../form/TextAreaFormInput";
import TextFormInput from "../../form/TextFormInput";
import Headline from "../../typo/Headline";
import SignatureUpload from "./SignatureUpload";

type Props = {
  user: DocumentSnapshot<DocumentData>;
  updateUser: (data: any) => Promise<void>;
};

export default function EditUserForm({ user, updateUser }: Props) {
  const [branches, isLoading] = useBranches();
  const [, , doc] = useFirestore();

  const handleUpdate = useCallback(
    (data: any) => {
      // If we're updating branchRef and it's a string (ID), convert it to a ref
      if ("branchRef" in data && typeof data.branchRef === "string") {
        const branchId = data.branchRef.split("/").pop();
        data.branchRef = doc(`branches/${branchId}`);
      }

      return updateUser(data);
    },
    [updateUser]
  );

  if (isLoading) {
    return <Spinner />;
  }

  const branchOptions: Option[] =
    branches.map((doc) => ({
      label: doc.data().name || "",
      value: doc.ref.path,
    })) || [];

  return (
    <div>
      <Headline title="Benutzer bearbeiten" />

      <TextFormInput
        label="Name"
        fieldPath="name"
        doc={user as any}
        updateDoc={handleUpdate as any}
      />

      <TextAreaFormInput
        label="Berichtsverfasser"
        fieldPath="protocolAuthor"
        doc={user as any}
        updateDoc={handleUpdate as any}
      />

      <TextFormInput
        label="Telefonnummer"
        fieldPath="phone"
        doc={user as any}
        updateDoc={handleUpdate as any}
      />

      <SelectFormInput
        label="Niederlassung"
        fieldPath="branchRef"
        placeholder="Niederlassung auswählen"
        options={branchOptions}
        doc={user as any}
        updateDoc={handleUpdate as any}
      />

      <SignatureUpload doc={user as any} updateDoc={handleUpdate as any} />
    </div>
  );
}
