import { useFirebaseObject } from "./useFirebaseObject";
import { SuggestionType } from "./useSuggestions";

export const useSuggestion = (suggestionId: string, type: SuggestionType) => {
  const [suggestion, updateSuggestion, isLoading] = useFirebaseObject(
    [`${type}Suggestions`, suggestionId].join("/")
  );

  return { suggestion, updateSuggestion, isLoading };
};
