import { addDoc } from "firebase/firestore";
import { useCallback } from "react";
import { useFirestore } from "./useFirestore.web";
import { SuggestionType } from "./useSuggestions";

export const useCreateSuggestion = (suggestionType: SuggestionType) => {
  const [tenantId, , , collection] = useFirestore();

  return useCallback(
    async (data: any) =>
      addDoc(collection(`${suggestionType}Suggestions`), data),
    [tenantId]
  );
};
