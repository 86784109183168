import { Formik, FormikHelpers } from "formik";
import { Button, Stack, useToast } from "native-base";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { object, string } from "yup";
import { useCreateSuggestion } from "../../../hooks/useCreateSuggestion.web";
import { SuggestionType, useSuggestions } from "../../../hooks/useSuggestions";
import FormikTextFormInput from "../../form/FormikTextFormInput";
import Headline from "../../typo/Headline";

const initialValues = {
  title: "",
  description: "",
  levels: "",
  availableInAreas: "",
};

type FormValues = typeof initialValues;

const validationSchema = object().shape({
  title: string(),
  description: string(),
  levels: string(),
  availableInAreas: string(),
});

export default function CreateSuggestion() {
  const { type } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [suggestions] = useSuggestions(type as SuggestionType, "");
  const addSuggestion = useCreateSuggestion(type as SuggestionType);

  const createSuggestion = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { setSubmitting } = formikHelpers;
      const { title, description, levels, availableInAreas } = values;

      const nextOrder = suggestions.length
        ? Math.max(...suggestions.map((s) => s.get<number>("order") || 0)) + 1
        : 0;

      const data: Record<string, any> = {
        title,
        description,
        order: nextOrder,
      };

      if (type === "area") {
        if (levels) {
          data.levels = levels
            .split(",")
            .map((l) => parseInt(l.trim()))
            .filter((l) => !isNaN(l));
        }

        if (availableInAreas) {
          data.availableInAreas = availableInAreas
            .split(",")
            .map((a) => a.trim())
            .filter(Boolean);
        }
      }

      await addSuggestion(data);

      toast.show({
        placement: "top",
        title: "Der Vorschlag wurde erfolgreich erstellt.",
      });

      navigate(`/admin/suggestions/${type}`);

      setSubmitting(false);
    },
    [suggestions, addSuggestion, type, navigate, toast]
  );

  return (
    <Stack px={5} py={6}>
      <Headline title="Vorschlag erstellen" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createSuggestion}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Stack space={4}>
            <FormikTextFormInput fieldPath="title" label="Titel" />

            {type === "width" && (
              <FormikTextFormInput
                fieldPath="description"
                label="Beschreibung"
              />
            )}

            {type === "area" && (
              <>
                <FormikTextFormInput
                  fieldPath="levels"
                  label="Level (kommagetrennt)"
                />

                <FormikTextFormInput
                  fieldPath="availableInAreas"
                  label="Verfügbar in Bereichen (kommagetrennt)"
                />
              </>
            )}

            <Button
              isLoading={isSubmitting}
              onPress={() => handleSubmit()}
              alignSelf="flex-start"
            >
              Vorschlag erstellen
            </Button>
          </Stack>
        )}
      </Formik>
    </Stack>
  );
}
