import React, { useCallback } from "react";
import { Spinner, Stack } from "native-base";
import { useNavigate, useParams } from "react-router-dom";
import { useBranch } from "../../hooks/useBranch";
import EditBranchForm from "./branch/EditBranchForm";
import DeleteButton from "../shared/DeleteButton.web";

export default function EditBranch() {
  const { branchId } = useParams();
  const navigate = useNavigate();

  const { branch, updateBranch, isLoading } = useBranch(branchId ?? "");

  const afterDelete = useCallback(() => {
    navigate("/admin/branches");
  }, [navigate]);

  if (isLoading || !branch) {
    return <Spinner />;
  }

  return (
    <Stack space={8} px={5} py={6}>
      <EditBranchForm
        branch={branch as any}
        updateBranch={updateBranch as any}
      />

      <div>
        <DeleteButton
          title="Niederlassung löschen"
          description="Möchten Sie diese Niederlassung wirklich löschen?"
          object={branch as any}
          afterDelete={afterDelete}
        />
      </div>
    </Stack>
  );
}
