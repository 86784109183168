import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import {
  DocumentData,
  onSnapshot,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

export const useObjectsAppointments = (
  _projectId: string,
  objectIds: string[]
) => {
  const [tenantId, , , collection] = useFirestore();
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);

  useEffect(() => {
    setIsLoading(true);

    if (!tenantId) {
      return;
    }

    if (objectIds.length <= 0) {
      setAppointments([]);
      setIsLoading(false);
      return;
    }

    const unsub = onSnapshot(collection("appointments"), (snapshot) => {
      // we need to filter like this as we cannot use the in query as it only supports filtering up to 10 items
      const filtered = snapshot.docs.filter((s) => {
        const objectId = s.get("objectRef")?.id;

        return objectIds.includes(objectId);
      });

      const sorted = orderBy(
        filtered,
        [(d) => d.get("appointmentDate")],
        ["asc"]
      );

      setAppointments(sorted);

      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  }, [tenantId, setIsLoading, setAppointments, objectIds]);

  return [appointments, isLoading] as const;
};
