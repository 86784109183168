import React from "react";
import { Spinner, Stack } from "native-base";
import { useParams } from "react-router-dom";
import { useTenant } from "../../hooks/useTenant";
import EditTenantForm from "./tenant/EditTenantForm";
import ChangeStatus from "./tenant/ChangeStatus";

export default function EditTenant() {
  const { tenantId } = useParams();

  const { tenant, updateTenant, isLoading } = useTenant(tenantId ?? "");

  if (isLoading || !tenant) {
    return <Spinner />;
  }

  return (
    <Stack space={8} px={5} py={6}>
      <EditTenantForm
        tenant={tenant as any}
        updateTenant={updateTenant as any}
      />

      <div>
        <ChangeStatus tenant={tenant} updateTenant={updateTenant} />
      </div>
    </Stack>
  );
}
