import React from "react";
import FormikSelectFormInput from "../../form/FormikSelectFormInput";
import { useCurrentUserRoles } from "../../../hooks/useCurrentUserRoles";
import { roleOptions } from "../../../hooks/useUser";

type Props = {
  label: string;
};

export default function RoleSelect({ label }: Props) {
  const [, hasRole] = useCurrentUserRoles();

  const allowedRoles = roleOptions.filter(({ value }) => {
    if (value === "superadmin") {
      return hasRole("superadmin");
    } else if (hasRole("admin")) {
      return true;
    }
  });

  return (
    <FormikSelectFormInput
      fieldPath="role"
      label={label}
      placeholder="Rolle wählen"
      options={allowedRoles}
    />
  );
}
