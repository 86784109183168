import { Button, Spinner } from "native-base";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useBranches } from "../../../hooks/useBranches";

export default function BranchesList() {
  const [branches, isLoading] = useBranches();

  const navigate = useNavigate();

  const editBranch = useCallback(
    (branchId: string) => {
      navigate(`/admin/branches/edit/${branchId}`);
    },
    [navigate]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Strasse</th>
          <th>PLZ</th>
          <th>Ort</th>
          <th>Aktionen</th>
        </tr>
      </thead>
      <tbody>
        {branches.map((branch) => {
          const branchData = branch.data();
          const { id } = branch;
          const { name, street, zipCode, city } = branchData;

          return (
            <tr key={id}>
              <td>{name}</td>
              <td>{street}</td>
              <td>{zipCode}</td>
              <td>{city}</td>
              <td>
                <Button variant="outline" onPress={() => editBranch(id)}>
                  Bearbeiten
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
