import { useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../App.web";
import { useCurrentTenantPrefix } from "./useCurrentTenantPrefix";

export const useGetFirebaseObject = (docPath: string) => {
  const [tenantId, withTenantPrefix] = useCurrentTenantPrefix();

  return useCallback(async () => {
    if (!tenantId) {
      return;
    }

    const docRef = doc(firestore, withTenantPrefix(docPath));

    const docSnapshot = await getDoc(docRef);

    return docSnapshot.data();
  }, [tenantId, docPath]);
};
