import React from "react";
import { Link } from "react-router-dom";
import { ScrollView } from "native-base";
import { Protocol } from "../WebAccess";
import TenantLogo from "./TenantLogo";

type Props = {
  protocols: Protocol[];
};

export default function WebProtocolSelector({ protocols }: Props) {
  const firstProtocol = protocols[0];

  return (
    <ScrollView height="100vh">
      <div
        className="webprotocol"
        style={
          {
            "--primary-color": firstProtocol?.tenant?.primaryColor,
          } as React.CSSProperties
        }
      >
        <TenantLogo tenant={firstProtocol?.tenant} />

        <h1>Rissprotokolle</h1>

        {protocols.map((protocol, idx) => {
          const { title, owner, protocolPDFUrl } = protocol;

          return (
            <div key={protocolPDFUrl}>
              <h2>{title}</h2>

              <div className="with-line-breaks">{owner}</div>

              <div>
                <Link to={`${idx}`}>Zum Webprotokoll</Link>
              </div>

              <div>
                <a
                  href={protocolPDFUrl}
                  download={`${title}.pdf`}
                  target="_blank"
                >
                  Als PDF herunterladen
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </ScrollView>
  );
}
