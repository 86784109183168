import { useContext } from "react";
import { CurrentUserContext } from "../App";
import { useBranch } from "./useBranch";
import { useBranches } from "./useBranches";
import { useUser } from "./useUser";

export const useBranchForUser = (userId?: string) => {
  const [branches] = useBranches();
  const firstBranchOfTenant = branches[0];

  const currentUser = useContext(CurrentUserContext);
  const { user, isLoading } = useUser(
    userId ? userId : currentUser?.user?.email ?? ""
  );

  const { branchRef } = user?.data() || {};
  const branchId = branchRef?.id;
  const { branch } = useBranch(branchId || "");

  return [branch ?? firstBranchOfTenant, isLoading] as const;
};
