import React, { useContext } from "react";
import { Box, Select, VStack } from "native-base";
import { useNavigate } from "react-router-dom";
import { useTenants } from "../../../hooks/useTenants";
import { CurrentUserContext } from "../../../App";

export default function TenantSwitcher() {
  const navigate = useNavigate();

  const [tenants] = useTenants();
  const currentUser = useContext(CurrentUserContext);

  const tenantId = currentUser?.tenantId;

  if (tenants.length === 0) {
    return null;
  }

  return (
    <VStack space={1}>
      <Box>
        <Select
          selectedValue={tenantId ?? ""}
          onValueChange={(tenantId) => {
            currentUser?.setTenantId(tenantId);
            navigate(`/admin/projects`);
          }}
          bg="white"
          placeholder="Mandant auswählen..."
        >
          {tenants.map((tenant) => {
            const { id } = tenant;
            const { name, isActive } = tenant.data() ?? {};

            return (
              <Select.Item
                key={id}
                label={`${name}${!isActive ? " (Inaktiv)" : ""}`}
                value={id}
              />
            );
          })}
        </Select>
      </Box>
    </VStack>
  );
}
