import React, { useCallback } from "react";
import { Button, Stack } from "native-base";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import Subsection from "../../typo/Subsection";
import ActiveState from "../../shared/ActiveState";

interface Props {
  tenant: DocumentSnapshot<DocumentData>;
  updateTenant: (data: any) => Promise<void>;
}

export default function ChangeStatus({ tenant, updateTenant }: Props) {
  if (!tenant) {
    return null;
  }

  const { isActive } = tenant.data() ?? {};

  const changeState = useCallback(
    async (newState: boolean) => {
      await updateTenant({ isActive: newState });
    },
    [updateTenant]
  );

  return (
    <Stack space={4}>
      <Subsection title="Status ändern" />

      <ActiveState isActive={isActive} />

      <Button onPress={() => changeState(!isActive)}>
        {isActive ? "Mandant deaktivieren" : "Mandant aktivieren"}
      </Button>
    </Stack>
  );
}
