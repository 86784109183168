import React, { useMemo } from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { orderBy } from "lodash";
import { useUsers } from "../../../hooks/useUsers";
import SelectFormInput from "../../form/SelectFormInput";

type Props = {
  fieldPath: string;
  label?: string;
  placeholder?: string;
  doc: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  updateDoc: (data: any) => Promise<void>;
};

export default function UserSelectInput({
  fieldPath,
  label,
  placeholder = "Benutzer auswählen...",
  doc,
  updateDoc,
}: Props) {
  const [users, isLoading] = useUsers();

  const options = useMemo(() => {
    if (!users) return [];

    const userOptions = users.map((user) => {
      const { id } = user;
      const { eMail, name, isActive } = user.data() ?? {};
      const displayName = name ?? eMail;

      return {
        value: id,
        label: `${displayName}${!isActive ? " (Inaktiv)" : ""}`,
        sortKey: displayName?.toLowerCase() ?? "",
      };
    });

    return orderBy(userOptions, ["sortKey"]).map(({ value, label }) => ({
      value,
      label,
    }));
  }, [users]);

  if (isLoading) {
    return null;
  }

  return (
    <SelectFormInput
      fieldPath={fieldPath}
      label={label}
      placeholder={placeholder}
      options={options}
      doc={doc as any}
      updateDoc={updateDoc as any}
    />
  );
}
