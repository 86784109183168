import {
  doc,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { firestore } from "../App.web";
import { useCurrentTenantPrefix } from "./useCurrentTenantPrefix";

export const useFirebaseObject = (docPath: string, skipPrefix?: boolean) => {
  const [tenantId, withTenantPrefix] = useCurrentTenantPrefix();
  const [isLoading, setIsLoading] = useState(true);
  const [object, setObject] = useState<
    DocumentSnapshot<DocumentData> | undefined
  >();

  useEffect(() => {
    if (!tenantId || !docPath || docPath.endsWith("/")) {
      return;
    }

    const unsub = onSnapshot(
      doc(firestore, skipPrefix ? docPath : withTenantPrefix(docPath)),
      (doc) => {
        setObject(doc);
        setIsLoading(false);
      },
      (error) => console.error(error)
    );

    return () => {
      unsub();
    };
  }, [tenantId, docPath, setIsLoading]);

  const updateObject = useCallback(
    (data: any) => {
      if (!tenantId || !docPath || docPath.endsWith("/")) {
        return;
      }

      return updateDoc(
        doc(firestore, skipPrefix ? docPath : withTenantPrefix(docPath)),
        data
      );
    },
    [tenantId, docPath]
  );

  return [object, updateObject, isLoading] as const;
};
