import {
  collection,
  DocumentData,
  QueryDocumentSnapshot,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../App.web";
import { useCurrentTenantPrefix } from "./useCurrentTenantPrefix";

export const useFirestoreCollection = (
  collectionPath: string,
  skipPrefix?: boolean
) => {
  const [tenantId, withTenantPrefix] = useCurrentTenantPrefix();
  const [isLoading, setIsLoading] = useState(true);
  const [docs, setDocs] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);

  useEffect(() => {
    setIsLoading(true);

    if (!tenantId) {
      return;
    }

    const unsub = onSnapshot(
      collection(
        firestore,
        skipPrefix ? collectionPath : withTenantPrefix(collectionPath)
      ),
      (snapshot) => {
        setDocs(snapshot.docs);
        setIsLoading(false);
      }
    );

    return () => {
      unsub();
    };
  }, [tenantId, setIsLoading, setDocs, collectionPath]);

  return [docs, isLoading] as const;
};
