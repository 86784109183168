import { useCallback, useContext } from "react";
import { CurrentUserContext } from "../App";
import { UserRoles } from "../types";

export const useCurrentUserRoles = () => {
  const currentUser = useContext(CurrentUserContext);

  const roles: UserRoles[] = currentUser?.claims?.roles ?? [];

  const hasRole = useCallback(
    (role: UserRoles) => roles.includes(role),
    [roles]
  );

  return [roles, hasRole] as const;
};
