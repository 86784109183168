import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import { FormControl, Select, WarningOutlineIcon } from "native-base";
import { Option } from "./SelectFormInput.web";

type Props = {
  fieldPath: string;
  label?: string;
  placeholder?: string;
  options: Option[];
};

export default function FormikSelectFormInput({
  fieldPath,
  label,
  placeholder,
  options,
}: Props) {
  const {
    values,
    setFieldValue,
    handleBlur,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext();

  const errorMessage = get(errors, fieldPath);
  const isFieldTouched = get(touched, fieldPath);
  const isInvalid = errorMessage != null && isFieldTouched;

  const handleChange = useCallback(
    (value: string) => {
      setFieldValue(fieldPath, value);

      setTimeout(() => {
        setFieldTouched(fieldPath);
      }, 0);
    },
    [fieldPath, setFieldValue, setFieldTouched, handleBlur]
  );

  return (
    <FormControl mb="1" isInvalid={isInvalid}>
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <Select
        selectedValue={get(values, fieldPath)}
        placeholder={placeholder}
        mt={1}
        onValueChange={handleChange}
      >
        {options.map(({ label, value }) => (
          <Select.Item key={value} label={label} value={value} />
        ))}
      </Select>

      <FormControl.ErrorMessage
        leftIcon={<WarningOutlineIcon size="xs" />}
        mb={3}
      >
        {errorMessage}
      </FormControl.ErrorMessage>
    </FormControl>
  );
}
