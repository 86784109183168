import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useFirestoreCollection } from "./useFirestoreCollection";
import { firestore } from "../App.web";

export type UserCount = Record<string, { active: number; total: number }>;

export const useTenants = () => {
  const [userCounts, setUserCounts] = useState<UserCount>({});
  const [tenants, isLoading] = useFirestoreCollection("tenants", true);

  useEffect(() => {
    const fetchCounts = async () => {
      const counts: UserCount = {};

      for (const tenant of tenants) {
        const users = await getDocs(
          collection(firestore, ["tenants", tenant.id, "users"].join("/"))
        );

        for (const user of users.docs) {
          const { isActive } = user.data();

          counts[tenant.id] = {
            active: (counts[tenant.id]?.active ?? 0) + (isActive ? 1 : 0),
            total: (counts[tenant.id]?.total ?? 0) + 1,
          };
        }
      }

      setUserCounts(counts);
    };

    fetchCounts();
  }, [tenants]);

  return [tenants, userCounts, isLoading] as const;
};
