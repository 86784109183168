import { Box, Divider, Image, Stack, VStack } from "native-base";
import React from "react";
import VermflexLogo from "../../assets/vermflex-logo.png";
import { useCurrentTenant } from "../../hooks/useCurrentTenant";
import { useCurrentUserRoles } from "../../hooks/useCurrentUserRoles";
import NavigationItem from "./components/NavigationItem";
import TenantSwitcher from "./tenant/TenantSwitcher";

export default function Navigation() {
  const tenant = useCurrentTenant();
  const [, hasRole] = useCurrentUserRoles();

  const isSuperAdmin = hasRole("superadmin");
  const isAdmin = isSuperAdmin || hasRole("admin");

  const logoUrl = tenant?.get("logoImageUrl");

  return (
    <Box w="300px" bgColor="gray.50" p={5}>
      <VStack space={4} flex={1}>
        <Image
          source={{ uri: logoUrl || VermflexLogo }}
          w="full"
          h={8}
          mt={4}
          resizeMode="contain"
          alt="Logo"
        />

        {isSuperAdmin && (
          <>
            <TenantSwitcher />
            <Divider />
          </>
        )}

        <nav>
          <Stack space={2}>
            <NavigationItem to="/admin/projects">Aufträge</NavigationItem>

            {isAdmin && (
              <NavigationItem to="/admin/users">Benutzer</NavigationItem>
            )}

            {isAdmin && (
              <NavigationItem to="/admin/branches">
                Niederlassungen
              </NavigationItem>
            )}

            {isAdmin && (
              <NavigationItem to="/admin/suggestions/crack">
                Textvorschläge
              </NavigationItem>
            )}

            {isSuperAdmin && (
              <>
                <NavigationItem to="/admin/statistics">
                  Statistiken
                </NavigationItem>
                <NavigationItem to="/admin/tenants">Mandanten</NavigationItem>
              </>
            )}

            <NavigationItem to="/admin/profile">Account</NavigationItem>
          </Stack>
        </nav>
      </VStack>
    </Box>
  );
}
