import { useFirebaseObject } from "./useFirebaseObject";

export const useTenant = (tenantId: string) => {
  const [tenant, updateTenant, isLoading] = useFirebaseObject(
    ["tenants", tenantId].join("/"),
    true
  );

  return { tenant, updateTenant, isLoading };
};
