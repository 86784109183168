import { useCallback } from "react";
import { addDoc, Timestamp } from "firebase/firestore";
import { useObjectType } from "./useObjectType";
import { useFirestore } from "./useFirestore.web";

type CrackType = "original" | "followUp";

export const useCreateCrack = (
  projectId: string,
  objectId: string,
  areaId: string
) => {
  const [tenantId, , doc, collection] = useFirestore();

  const objectType = useObjectType(projectId!, objectId!);

  return useCallback(
    async (
      mainCrackId?: string,
      crackType?: CrackType,
      noVisibleChange?: boolean
    ) => {
      const areaRef = doc(
        ["projects", projectId, "objects", objectId, "areas", areaId].join("/")
      );
      const data: any = {
        areaRef,
        lengthUnit: "cm",
        createdAt: Timestamp.now(),
      };

      if (crackType) {
        data.crackType = crackType;
      }

      if (mainCrackId) {
        data.mainCrackId = mainCrackId;
      }

      if (noVisibleChange) {
        data.noVisibleChange = noVisibleChange;
      }

      if (objectType === "street") {
        data.isHiddenInProtocol = true;
      }

      return addDoc(
        collection(
          ["projects", projectId, "objects", objectId, "cracks"].join("/")
        ),
        data
      );
    },
    [tenantId, projectId, objectId, areaId, objectType]
  );
};
