import Constants from "expo-constants";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { Box, NativeBaseProvider } from "native-base";
import "normalize.css";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { enableExperimentalWebImplementation } from "react-native-gesture-handler";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import AreaDetails from "./components/web/AreaDetails";
import Branches from "./components/web/Branches";
import CreateBranch from "./components/web/CreateBranch";
import CreateTenant from "./components/web/CreateTenant";
import CreateUser from "./components/web/CreateUser";
import EditBranch from "./components/web/EditBranch";
import EditSuggestion from "./components/web/suggestions/EditSuggestion";
import CreateSuggestion from "./components/web/suggestions/CreateSuggestion";
import EditTenant from "./components/web/EditTenant";
import EditUser from "./components/web/EditUser";
import Error404 from "./components/web/Error404";
import Login from "./components/web/Login";
import ManageSuggestions from "./components/web/ManageSuggestions";
import ObjectDetails from "./components/web/ObjectDetails";
import Profile from "./components/web/Profile";
import Project from "./components/web/Project";
import Projects from "./components/web/Projects";
import Statistics from "./components/web/Statistics";
import Tenants from "./components/web/Tenants";
import Users from "./components/web/Users";
import WebAccess from "./components/web/WebAccess";
import firebaseConfig from "./config/prod/firebase.json";
import firebaseConfigTest from "./config/test/firebase.json";
import "./global.css";
import RedirectBasedOnAuth from "./routes/RedirectBasedOnAuth";
import RequireAuth from "./routes/RequireAuth";
import Root from "./routes/Root";
import { theme } from "./theme";
import { CurrentUser, UserRoles } from "./types.web";

enableExperimentalWebImplementation(true);

export const CurrentUserContext = createContext<CurrentUser | null>(null);

export const app = initializeApp(
  Constants.expoConfig?.extra?.isTest ? firebaseConfigTest : firebaseConfig
);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Error404 />,
    children: [
      {
        path: "admin",
        element: <Root />,
        children: [
          {
            index: true,
            element: <RedirectBasedOnAuth />,
          },
          {
            path: "statistics",
            element: (
              <RequireAuth roles={["superadmin"]}>
                <Statistics />
              </RequireAuth>
            ),
          },
          {
            path: "projects",
            children: [
              {
                index: true,
                element: (
                  <RequireAuth>
                    <Projects />
                  </RequireAuth>
                ),
              },
              {
                path: ":projectId",
                children: [
                  {
                    index: true,
                    element: (
                      <RequireAuth>
                        <Project />
                      </RequireAuth>
                    ),
                  },
                  {
                    path: ":objectId",
                    children: [
                      {
                        index: true,
                        element: (
                          <RequireAuth>
                            <ObjectDetails />
                          </RequireAuth>
                        ),
                      },
                      {
                        path: ":areaId",
                        element: (
                          <RequireAuth>
                            <AreaDetails />
                          </RequireAuth>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "users",
            children: [
              {
                index: true,
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <Users />
                  </RequireAuth>
                ),
              },
              {
                path: "create",
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <CreateUser />
                  </RequireAuth>
                ),
              },
              {
                path: "edit/:userId",
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <EditUser />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "tenants",
            children: [
              {
                index: true,
                element: (
                  <RequireAuth roles={["superadmin"]}>
                    <Tenants />
                  </RequireAuth>
                ),
              },
              {
                path: "create",
                element: (
                  <RequireAuth roles={["superadmin"]}>
                    <CreateTenant />
                  </RequireAuth>
                ),
              },
              {
                path: "edit/:tenantId",
                element: (
                  <RequireAuth roles={["superadmin"]}>
                    <EditTenant />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "branches",
            children: [
              {
                index: true,
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <Branches />
                  </RequireAuth>
                ),
              },
              {
                path: "create",
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <CreateBranch />
                  </RequireAuth>
                ),
              },
              {
                path: "edit/:branchId",
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <EditBranch />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "suggestions",
            children: [
              {
                index: true,
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <Navigate to="/admin/suggestions/crack" replace />
                  </RequireAuth>
                ),
              },
              {
                path: ":type",
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <ManageSuggestions />
                  </RequireAuth>
                ),
              },
              {
                path: "create/:type",
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <CreateSuggestion />
                  </RequireAuth>
                ),
              },
              {
                path: "edit/:type/:suggestionId",
                element: (
                  <RequireAuth roles={["admin", "superadmin"]}>
                    <EditSuggestion />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "profile",
            element: (
              <RequireAuth>
                <Profile />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "admin/login",
        element: <Login />,
        errorElement: <Error404 />,
      },
      {
        index: true,
        element: <WebAccess />,
        errorElement: <Error404 />,
      },
      {
        path: ":projectOrObjectId",
        element: <WebAccess />,
        errorElement: <Error404 />,
        children: [
          {
            path: ":protocolIndex",
            element: <WebAccess />,
            errorElement: <Error404 />,
          },
        ],
      },
    ],
  },
]);

export default function App() {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<CurrentUser | null>(null);

  const setTenantId = useCallback((tenantId: string) => {
    setUser((prevUser) => {
      if (!prevUser) return null;

      return {
        ...prevUser,
        tenantId,
      };
    });
  }, []);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (user) => {
      let currentUser: CurrentUser = null;

      if (user) {
        const customClaims = await user.getIdTokenResult();

        const { tenantId, roles = [] } = customClaims.claims;

        currentUser = {
          user,
          claims: {
            tenantId: tenantId as string,
            roles: roles as UserRoles[],
          },
          tenantId: tenantId as string,
          setTenantId,
        };
      }

      setUser(currentUser);

      if (initializing) {
        setInitializing(false);
      }
    });

    return unsub;
  }, [initializing, setUser, setInitializing]);

  if (initializing) return null;

  return (
    <CurrentUserContext.Provider value={user}>
      <NativeBaseProvider theme={theme}>
        <Box>
          <RouterProvider router={router} />
        </Box>
      </NativeBaseProvider>
    </CurrentUserContext.Provider>
  );
}
