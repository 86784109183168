import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, useToast } from "native-base";
import { Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import Headline from "../typo/Headline";
import { formikErrorMessages } from "../../helper/formik-helper";
import FormikTextFormInput from "../form/FormikTextFormInput";
import { useCreateBranch } from "../../hooks/useCreateBranch";

const initialValues = {
  name: "",
  street: "",
  zipCode: "",
  city: "",
};

type FormValues = typeof initialValues;

const validationSchema = object().shape({
  name: string().required(formikErrorMessages.required),
  street: string().required(formikErrorMessages.required),
  zipCode: string().required(formikErrorMessages.required),
  city: string().required(formikErrorMessages.required),
});

export default function CreateBranch() {
  const toast = useToast();
  const navigate = useNavigate();
  const createBranch = useCreateBranch();

  const handleSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { setSubmitting } = formikHelpers;

      await createBranch(values);

      toast.show({
        placement: "top",
        title: "Die Niederlassung wurde erfolgreich erstellt.",
      });

      navigate("/admin/branches");
      setSubmitting(false);
    },
    [navigate, toast, createBranch]
  );

  return (
    <Stack px={5} py={6}>
      <Headline title="Niederlassung erstellen" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Stack space={4}>
            <FormikTextFormInput fieldPath="name" label="Name" />
            <FormikTextFormInput fieldPath="street" label="Strasse" />
            <FormikTextFormInput fieldPath="zipCode" label="PLZ" />
            <FormikTextFormInput fieldPath="city" label="Ort" />

            <Button
              isLoading={isSubmitting}
              onPress={() => handleSubmit()}
              alignSelf="flex-start"
            >
              Niederlassung erstellen
            </Button>
          </Stack>
        )}
      </Formik>
    </Stack>
  );
}
