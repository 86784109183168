import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AddIcon, Button } from "native-base";
import { SuggestionType } from "../../../hooks/useSuggestions";

interface Props {
  type: SuggestionType;
}

export default function CreateSuggestionButton({ type }: Props) {
  const navigate = useNavigate();

  const onCreateObject = useCallback(async () => {
    navigate(`/admin/suggestions/create/${type}`);
  }, [navigate, type]);

  return (
    <Button
      onPress={onCreateObject}
      colorScheme="primary"
      startIcon={<AddIcon />}
      alignSelf="flex-end"
    >
      Vorschlag erstellen
    </Button>
  );
}
