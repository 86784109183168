import { Form, Formik, FormikHelpers } from "formik";
import { Button, Stack } from "native-base";
import React, { useCallback } from "react";
import { object, string } from "yup";
import { formikErrorMessages } from "../../../helper/formik-helper";
import FormikTextFormInput from "../../form/FormikTextFormInput";
import { Protocol } from "../WebAccess";
import { getBackendURL } from "../../../environment";

const initialValues = {
  eMail: "",
};

type FormValues = typeof initialValues;

const validationSchema = object().shape({
  eMail: string()
    .required(formikErrorMessages.required)
    .email(formikErrorMessages.eMail),
});

type Props = {
  protocol: Protocol;
  afterArchiveRequested: () => void;
};

export default function RequestArchiveForm({
  protocol,
  afterArchiveRequested,
}: Props) {
  const {
    tenant: { id: tenantId },
    projectId,
    objectId,
  } = protocol;

  const onSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { eMail } = values;
      const { setSubmitting } = formikHelpers;

      await fetch(`${getBackendURL()}/archive`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId,
          projectId,
          objectId,
          recipient: eMail,
        }),
      });

      setSubmitting(false);
      afterArchiveRequested();
    },
    [tenantId, projectId, objectId, afterArchiveRequested]
  );

  return (
    <div>
      <p>
        Sie können ein ZIP-Archiv aller Fotos anfordern. Sie erhalten den
        Download-Link in ca. einer Stunde per E-Mail.
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Stack space={2}>
              <FormikTextFormInput fieldPath="eMail" label="E-Mail" />

              <Button
                mt={4}
                onPress={submitForm}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Download-Link anfordern
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
}
