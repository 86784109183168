import React, { useContext } from "react";
import { Stack, Text } from "native-base";
import Headline from "../typo/Headline";
import LogoutButton from "./components/LogoutButton";
import Spacer from "./components/Spacer";
import { CurrentUserContext } from "../../App.web";
import { useUser } from "../../hooks/useUser";
import EditUserForm from "./user/EditUserForm";

export default function Profile() {
  const currentUser = useContext(CurrentUserContext);

  const { user, updateUser, isLoading } = useUser(
    currentUser?.user?.email ?? ""
  );

  return (
    <Stack px={5} py={6}>
      <Headline title="Account" />

      {currentUser?.user && (
        <>
          <Text>Eingeloggt als {currentUser?.user?.email}</Text>
          <LogoutButton />

          <Spacer />

          {!isLoading && user && updateUser && (
            <EditUserForm user={user as any} updateUser={updateUser as any} />
          )}
        </>
      )}
    </Stack>
  );
}
