import { updateDoc } from "firebase/firestore";
import { useToast } from "native-base";
import { useCallback, useState } from "react";
import { useAuthorFields } from "./useAuthorFields";
import { useCreateInitialWebAccess } from "./useCreateInitialWebAccess";
import { useCurrentUser } from "./useCurrentUser";
import { useFirestore } from "./useFirestore";
import { getBackendURL } from "../environment";

export const useCreateProtocol = (projectId: string, objectId: string) => {
  const [tenantId, , doc] = useFirestore();

  const [user] = useCurrentUser();
  const { signatureImageUrl, city } = useAuthorFields(user);

  const createInitialWebAccess = useCreateInitialWebAccess(projectId, objectId);

  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const createProtocol = useCallback(
    async (includeDetailPhotos: boolean) => {
      setIsLoading(true);

      if (!tenantId) {
        return;
      }

      try {
        const objectRef = doc(
          ["projects", projectId, "objects", objectId].join("/")
        );

        //@ts-ignore
        await updateDoc(objectRef, {
          protocolDOCXUrl: null,
          protocolPDFUrl: null,
        });

        await createInitialWebAccess();

        await fetch(`${getBackendURL()}/produce-message`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payload: JSON.stringify({
              job: "createDocument",
              tenantId,
              projectId,
              objectId,
              includeDetailPhotos,
              signatureImageUrl,
              city,
            }),
          }),
        });

        //@ts-ignore
        await updateDoc(objectRef, {
          protocolCreatedAt: new Date(),
        });
      } catch (e) {
        console.error(e);

        toast.show({
          placement: "top",
          title: "Protokoll konnte nicht generiert werden",
          description: String(e),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      tenantId,
      projectId,
      objectId,
      signatureImageUrl,
      city,
      setIsLoading,
      toast,
    ]
  );

  return [createProtocol, isLoading] as const;
};
