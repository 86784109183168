import { useContext } from "react";
import { CurrentUserContext } from "../App";
import { useTenant } from "./useTenant";

export const useCurrentTenant = () => {
  const currentUser = useContext(CurrentUserContext);
  const tenantId = currentUser?.tenantId;
  const { tenant } = useTenant(tenantId || "");

  return tenant;
};
