import { useCallback } from "react";
import { addDoc, Timestamp } from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

interface CreateBranchData {
  name: string;
  street: string;
  zipCode: string;
  city: string;
}

export const useCreateBranch = () => {
  const [tenantId, , , collection] = useFirestore();

  return useCallback(
    async (data: CreateBranchData) =>
      addDoc(collection("branches"), {
        ...data,
        createdAt: Timestamp.now(),
      }),
    [tenantId]
  );
};
