import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "native-base";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useSuggestions, SuggestionType } from "../../../hooks/useSuggestions";
import { useSortSuggestions } from "../../../hooks/useSortSuggestions";

type Props = {
  type: SuggestionType;
};

type RowProps = {
  id: string;
  type: SuggestionType;
  title: string;
  description?: string;
  levels?: number[];
  availableInAreas?: string[];
  onEdit: (id: string) => void;
};

function SortableRow({
  id,
  type,
  title,
  description,
  levels,
  availableInAreas,
  onEdit,
}: RowProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <td>{title}</td>
      {type === "width" && <td>{description}</td>}
      {type === "area" && <td>{levels?.join(", ") || "Alle"}</td>}
      {type === "area" && <td>{availableInAreas?.join(", ") || "Alle"}</td>}
      <td>
        <Button variant="outline" onPress={() => onEdit(id)}>
          Bearbeiten
        </Button>
      </td>
    </tr>
  );
}

export default function SuggestionsList({ type }: Props) {
  const [suggestions, isLoading] = useSuggestions(type, "");
  const navigate = useNavigate();
  const [draggingSuggestion, setDraggingSuggestion] = useState<string>();

  const { suggestionsToSort, handleDragEnd, handleDragStart } = useSortSuggestions(
    suggestions,
    type,
    setDraggingSuggestion
  );

  const editSuggestion = useCallback(
    (suggestionId: string) => {
      navigate(`/admin/suggestions/edit/${type}/${suggestionId}`);
    },
    [navigate, type]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      collisionDetection={closestCenter}
    >
      <table>
        <thead>
          <tr>
            <th>Titel</th>
            {type === "width" && <th>Details</th>}
            {type === "area" && <th>Level</th>}
            {type === "area" && <th>Verfügbar in Bereichen</th>}
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <SortableContext
            items={suggestionsToSort}
            strategy={verticalListSortingStrategy}
          >
            {suggestions.map((suggestion) => {
              const { id } = suggestion;
              const { title, description, levels, availableInAreas } =
                suggestion.data() || {};

              return (
                <SortableRow
                  key={id}
                  id={id}
                  type={type}
                  title={title}
                  description={description}
                  levels={levels}
                  availableInAreas={availableInAreas}
                  onEdit={editSuggestion}
                />
              );
            })}
          </SortableContext>
        </tbody>
      </table>
    </DndContext>
  );
}
