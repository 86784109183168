import { useCallback } from "react";
import { DragStartEvent, DragEndEvent } from "@dnd-kit/core";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { useFirestore } from "./useFirestore.web";

export type UpdateSuggestionSorting = {
  doc: QueryDocumentSnapshot<DocumentData>;
  order: number;
}[];

export const useSortSuggestions = (
  suggestions: QueryDocumentSnapshot<DocumentData>[],
  type: string,
  setDraggingSuggestion?: (id?: string) => void,
) => {
  const [, firestore] = useFirestore();

  const updateSuggestionSorting = useCallback(
    async (updatedOrders: UpdateSuggestionSorting) => {
      const batch = firestore.batch();

      updatedOrders.forEach(({ doc, order }) => {
        batch.update(doc.ref, { order });
      });

      await batch.commit();
    },
    [firestore],
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;

      const updatedOrders: UpdateSuggestionSorting = [];

      if (over != null && active.id !== over.id) {
        const fromIndex = suggestions.findIndex((s) => s.id === active.id);
        const toIndex = suggestions.findIndex((s) => s.id === over.id);

        if (fromIndex >= 0 && toIndex >= 0) {
          if (fromIndex < toIndex) {
            // moving from left to right
            for (let i = fromIndex + 1; i <= toIndex; i++) {
              const currentItem = suggestions[i];
              updatedOrders.push({
                doc: currentItem,
                order: parseInt(currentItem.get("order")) - 1,
              });
            }
          } else {
            // moving from right to left
            for (let i = toIndex; i <= fromIndex - 1; i++) {
              const currentItem = suggestions[i];
              updatedOrders.push({
                doc: currentItem,
                order: parseInt(currentItem.get("order")) + 1,
              });
            }
          }

          updatedOrders.push({
            doc: suggestions[fromIndex],
            order: suggestions[toIndex].get("order"),
          });

          await updateSuggestionSorting(updatedOrders);
        }

        setDraggingSuggestion && setDraggingSuggestion(undefined);
      }
    },
    [suggestions, setDraggingSuggestion, updateSuggestionSorting],
  );

  const suggestionsToSort: { id: string }[] = suggestions.map(({ id }) => ({ id }));

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setDraggingSuggestion && setDraggingSuggestion(event.active.id as string);
    },
    [setDraggingSuggestion],
  );

  return { suggestionsToSort, handleDragEnd, handleDragStart };
};
