import { useCallback, useState } from "react";
import { addDoc, Timestamp, updateDoc } from "firebase/firestore";
import { getImageSize } from "react-image-size";
import { useToast } from "native-base";
import { useDropzone } from "react-dropzone";
import { useCreateThumbnail } from "./useCreateThumbnail";
import { colors } from "../colors";
import { useFirestore } from "./useFirestore.web";
import { useUserIDToken } from "./useUserIDToken.web";
import { getBackendURLDedicated } from "../environment";

type PhotoType =
  | "detailPhoto"
  | "overviewPhoto"
  | "objectOverviewPhoto"
  | "overviewMap";

const photoTypeToCollectionMapping = {
  detailPhoto: "photos",
  overviewPhoto: "photos",
  objectOverviewPhoto: "overviewPhotos",
  overviewMap: "overviewMaps",
};

export const dragActiveStyle = (isDragAccept: boolean) =>
  isDragAccept
    ? {
        outline: `2px solid ${colors.primary}`,
        outlineOffset: "2px",
      }
    : undefined;

export const useUploadPhotoToObject = (
  photoType: PhotoType,
  projectId: string,
  objectId: string,
  crackId?: string,
  maxFiles?: number
) => {
  const [tenantId, , doc, collection] = useFirestore();
  const getUserIDToken = useUserIDToken();
  const toast = useToast();

  const [isUploading, setUploading] = useState(false);

  const createThumbnail = useCreateThumbnail(true);

  const uploadFile = useCallback(
    async (file: File) => {
      const { name } = file;
      const objectURL = URL.createObjectURL(file);
      const { width, height } = await getImageSize(objectURL);

      const thumbnail = await createThumbnail(objectURL);

      const data: any = {
        photoType,
        capturedAt: Timestamp.now(),
        width,
        height,
        localUrl: name,
      };

      const photoCollection = photoTypeToCollectionMapping[photoType];

      if (crackId) {
        data.crackRef = doc(
          ["projects", projectId, "objects", objectId, "cracks", crackId].join(
            "/"
          )
        );
      }

      const photoRef = await addDoc(
        collection(
          ["projects", projectId, "objects", objectId, photoCollection].join(
            "/"
          )
        ),
        data
      );

      try {
        const extension = name.split(".").pop();
        const authToken = await getUserIDToken();

        // Upload main photo
        const mainPhotoDestination = `photos/${tenantId ?? ""}/${projectId}/${objectId}/${photoRef.id}.${extension}`;
        const mainFormData = new FormData();
        mainFormData.append("file", file);
        mainFormData.append("tenantId", tenantId ?? "");
        mainFormData.append("destination", mainPhotoDestination);
        mainFormData.append("authToken", authToken ?? "");

        const mainResponse = await fetch(`${getBackendURLDedicated()}/upload`, {
          method: "POST",
          body: mainFormData,
        });

        const mainJson = await mainResponse.json();
        const {
          success: mainSuccess,
          error: mainError,
          fileUrl: mainUrl,
        } = mainJson;

        if (!mainSuccess || !mainUrl) {
          if (mainError) {
            toast.show({
              placement: "top",
              title: mainError,
            });
          }
          throw new Error(mainError || JSON.stringify(mainJson));
        }

        let thumbnailUrl: string | undefined = undefined;

        // Upload thumbnail if available
        if (thumbnail.success && thumbnail.base64) {
          const base64Response = await fetch(
            `data:image/jpeg;base64,${thumbnail.base64}`
          );
          const thumbnailFile = await base64Response.blob();
          const thumbnailDestination = `photos/${tenantId ?? ""}/${projectId}/${objectId}/${photoRef.id}-thumbnail.${extension}`;

          const thumbnailFormData = new FormData();
          thumbnailFormData.append("file", thumbnailFile);
          thumbnailFormData.append("tenantId", tenantId ?? "");
          thumbnailFormData.append("destination", thumbnailDestination);
          thumbnailFormData.append("authToken", authToken ?? "");

          const thumbnailResponse = await fetch(
            `${getBackendURLDedicated()}/upload`,
            {
              method: "POST",
              body: thumbnailFormData,
            }
          );

          const thumbnailJson = await thumbnailResponse.json();
          const { success: thumbSuccess, fileUrl: thumbUrl } = thumbnailJson;

          if (thumbSuccess && thumbUrl) {
            thumbnailUrl = thumbUrl;
          }
        }

        await updateDoc(photoRef, {
          url: mainUrl,
          thumbnailUrl,
          localUrl: null,
        });

        toast.show({
          placement: "top",
          title: "Das Foto wurde erfolgreich hochgeladen.",
        });
      } catch (e) {
        console.error(e);
        toast.show({
          placement: "top",
          title:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
        });
      }
    },
    [tenantId, photoType, projectId, objectId, crackId, getUserIDToken, toast]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);

      for (const file of acceptedFiles) {
        try {
          await uploadFile(file);
        } catch (e) {
          console.error(e);
        }
      }

      setUploading(false);
    },
    [uploadFile, setUploading]
  );

  const dropzone = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxFiles,
  });

  const { open: uploadPhoto } = dropzone;

  return [uploadPhoto, isUploading, dropzone] as const;
};
