import React from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import {
  Box,
  Center,
  FormControl,
  Icon,
  Pressable,
  Spinner,
  Stack,
  Text,
  View,
} from "native-base";
import { Feather } from "@expo/vector-icons";
import { dragActiveStyle, useUploadLogo } from "../../../hooks/useUploadLogo";
import Image from "../../shared/Image";

type Props = {
  doc: DocumentSnapshot<DocumentData>;
  updateDoc: (data: any) => Promise<void>;
};

export default function LogoUpload({ doc, updateDoc }: Props) {
  const [uploadLogo, isUploading, dropzone] = useUploadLogo(doc, updateDoc);

  const { getRootProps, isDragAccept } = dropzone;

  const logoImageUrl = doc.get("logoImageUrl");

  const uploadContent = (
    <Center h="full" w="full" py={4} px={8} flex={1}>
      <Text textAlign="center">
        <Stack space={2} alignItems="center">
          {isUploading ? (
            <Spinner color="white" />
          ) : (
            <Icon as={Feather} name="upload" color="white" size="xl" />
          )}
          <Text color="white" fontSize="sm" textAlign="center">
            Logo hochladen
          </Text>
        </Stack>
      </Text>
    </Center>
  );

  return (
    <FormControl mb="1">
      <FormControl.Label>Logo</FormControl.Label>

      <div {...getRootProps()} style={dragActiveStyle(isDragAccept)}>
        <Box w="400" h="100" bg="gray.50">
          <Pressable
            bgColor="gray.50"
            borderRadius="2"
            onPress={uploadLogo}
            _pressed={{ opacity: 0.5 }}
            w="full"
            h="full"
            flex={1}
          >
            {logoImageUrl && !isUploading ? (
              <View w="full" h="full">
                <Image imageURI={logoImageUrl} />
              </View>
            ) : (
              uploadContent
            )}
          </Pressable>
        </Box>
      </div>
    </FormControl>
  );
}
