import React from "react";
import { Stack } from "native-base";
import Headline from "../typo/Headline";
import CreateTenantButton from "../tenant/CreateTenantButton";
import TenantsList from "./components/TenantsList";
import Spacer from "./components/Spacer";

export default function Tenants() {
  return (
    <Stack px={5} py={6}>
      <Headline title="Mandanten" />

      <CreateTenantButton />

      <Spacer />

      <TenantsList />
    </Stack>
  );
}
